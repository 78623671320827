
body {
	font-family: Tahoma;
	padding-top: 50px;
	padding-bottom: 50px;
	background-color: #607C8A;
	background: url('../img/skema_background.png') no-repeat center center fixed;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}

/* make sidebar nav vertical */
@media (min-width: 768px) {
	.sidebar-nav .navbar .navbar-collapse {
		padding: 0;
		max-height: none;
	}

	.sidebar-nav .navbar ul {
		float: none;
	}

	.sidebar-nav .navbar ul:not {
		display: block;
	}

	.sidebar-nav .navbar li {
		float: none;
		display: block;
	}

	.sidebar-nav .navbar li a {
		padding-top: 12px;
		padding-bottom: 12px;
	}

	.sidebar {
		position: fixed !important;
		background-color: #f5f5f5;
		border-right: 1px solid #eee;
		bottom: 0;
		display: block;
		left: 0;
		top: 51px;
		overflow-x: hidden;
		z-index: 1000;
	}
}

.nav-sidebar {
	margin-bottom: 20px;
	margin-left: -20px;
	margin-right: -21px;
}

.nav-sidebar > li > a {
	padding-left: 20px;
	padding-right: 20px;
}

.nav-sidebar > .active > a, .nav-sidebar > .active > a:hover, .nav-sidebar > .active > a:focus {
	background-color: #428bca;
	color: red;
}

.form-signin {
	max-width: 330px;
	padding: 15px;
	margin: 0 auto;
}


.form-signin .form-signin-heading, .form-signin .checkbox {
	margin-bottom: 10px;
}

.form-signin .checkbox {
	font-weight: normal;
}

.form-signin .form-control {
	position: relative;
	font-size: 16px;
	height: auto;
	padding: 10px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.form-signin .form-control:focus {
	z-index: 2;
}

.form-signin input[type="text"] {
	margin-bottom: 10px;
	padding-bottom: 10px;
}

.form-signin input[type="email"] {
	margin-bottom: 10px;
	padding-bottom: 10px;
}

.form-signin input[type="password"] {
	margin-bottom: 10px;
}

.account-wall {
	margin-top: 20px;
	padding: 30px 0 20px 0;
	background-color: #f7f7f7;
	-moz-box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.3);
	-webkit-box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.3);
	box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.3);
}

.account-wall .loader {
	padding-left: 40px;
}

.login-title {
	color: #555;
	font-size: 18px;
	font-weight: 400;
	display: block;
}

.profile-img {
	width: 96px;
	height: 96px;
	margin: 0 auto 10px;
	display: block;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	border-radius: 50%;
}

.need-help {
	margin-top: 10px;
}

.new-account {
	display: block;
	margin-top: 10px;
}

.tr-group-header {
	background-color: #eee;
	height: 20px;
	font-weight: bold;
}

.highlight {
	padding: 9px 14px;
	margin-bottom: 14px;
	background-color: #f7f7f9;
	border: 1px solid #e1e1e8;
	border-radius: 4px;
}

.highlight .form-horizontal {
	margin: 9px 14px;
}

.topHeader {
	background-color: rgba(78, 98, 118, 1.0) !important;
	border-bottom: 1px solid rgb(52, 65, 78);
	height: 60px;
	padding-top: 7px;
	color: #fff !important;
}

.whiteText {
	color: #fff !important;
}

.yellowText {
	color: #ffcd03 !important;
}

.loginField {
	border: 1px solid white;
	background-color: #e6e6e6;
}

.btn-primary.disabled, .btn-primary[disabled], fieldset[disabled] .btn-primary, .btn-primary.disabled:hover, .btn-primary[disabled]:hover, fieldset[disabled] .btn-primary:hover, .btn-primary.disabled:focus, .btn-primary[disabled]:focus, fieldset[disabled] .btn-primary:focus, .btn-primary.disabled.focus, .btn-primary[disabled].focus, fieldset[disabled] .btn-primary.focus, .btn-primary.disabled:active, .btn-primary[disabled]:active, fieldset[disabled] .btn-primary:active, .btn-primary.disabled.active, .btn-primary[disabled].active, fieldset[disabled] .btn-primary.active {
	opacity: 1.0;
	color: lightgray !important;
	border-color: lightgrey !important;
}

.navbar-inverse {
	border: 0;
}

.nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
	background-color: inherit;
}

.nav > li > a:hover, .nav > li > a:focus {
	background-color: inherit;
	color: rgb(235, 133, 0) !important;
}

.MainPageColoredBox {
	height: 250px;
	width: 235px;
	margin: auto;
	background-color: rgba(74, 90, 98, 0.55);
	border: 1px solid white;
	position: relative;
	display: inline-table;
	margin: 25px;
}

.MainPageColoredBoxWide {
	height: 210px;
	width: 380px;
	margin: auto;
	background-color: rgba(74, 90, 98, 0.55);
	border: 1px solid white;
	position: relative;
	display: inline-table;
	margin: 25px;
}

.MainPageWhiteWithOrange {
	background-color: white;
	width: 100%;
	position: absolute;
	bottom: 0;
	height: 50px;
	color: rgb(235, 133, 0);
	font-size: 2.0em;
	padding-top: 4px;
}

.ImageIcon {
	margin: 20px;
}

a.OrangeText {
	color: rgb(235, 133, 0);
	text-decoration: none;
}

a.OrangeText:hover {
	color: rgb(190, 108, 0);
}

a {
	color: #2C4267;
}

.table {
	color: #2C4267;
}

.table-striped > tbody > tr:nth-of-type(2n) {
	background: #b1b6be;
}

.table-striped > tbody > tr:nth-of-type(2n+1) {
	background-color: #D6DDDD;
}

th {
	padding: 2px 8px !important;
	background-color: #FFCD03;
	border-left: 1px solid white;
	border-right: 1px solid white;
}

tr {
	border: 3px solid white;
}

td {
	border: 1px solid white;
}

table .showIfLast {
	display: none;
}

th.tablesort-sortable {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
	cursor: pointer;
}

.table-nowrap {
	table-layout: fixed;
}

.table-nowrap td {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

table .tablesort-sortable:after {
	content: "";
	float: right;
	margin-top: 7px;
	visibility: hidden;
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;

	border-top: none;
	border-bottom: 4px solid #000;
}

table .tablesort-desc:after {
	border-top: 4px solid #000;
	border-bottom: none;
}

table .tablesort-asc, table .tablesort-desc {
	background-color: rgba(255, 205, 3, .5);
}

table .tablesort-sortable:hover:after, table .tablesort-asc:after, table .tablesort-desc:after {
	visibility: visible;
}

dt {
	color: #2C4267;
	text-align: left !important;
	padding: 5px;
}

dd {
	color: #737373;
	padding: 5px;
}

dt.historicData {
	color: #2C4267;
	text-align: right !important;
}

dt.historicDataDate {
	color: #2C4267;
	text-align: left !important;
}

dd.historicData {
	color: #737373;
}

label {
	display: inline-block;
	cursor: pointer;
	position: relative;
	padding-left: 25px;
	margin-right: 15px;
	font-size: 13px;
}

.wrapper {
	width: 500px;
	margin: 50px auto;
}

input[type=radio],
input[type=checkbox] {
	display: none;
}

label:before {
	content: "";
	display: inline-block;
	width: 16px;
	height: 16px;
	margin-right: 10px;
	position: absolute;
	left: 0;
	bottom: 3px;
	background-color: #898889;
	box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, .3), 0 1px 0 0 rgba(255, 255, 255, .8);
}

.radio label:before {
	border-radius: 8px;
}

.checkbox label {
	margin-bottom: 10px;
}

.checkbox label:before {
	border-radius: 3px;

}

.bdk-checkbox-inline label {
	margin-bottom: 0px;
}

.bdk-checkbox-inline label:before {
	border-radius: 3px;
	bottom: -2px;
}

input[type=radio]:checked + label:before {
	content: "\2022";
	color: #FFCD03;
	font-size: 28px;
	text-align: center;
	line-height: 12px;
	padding-right: 1px;
}

input[type=checkbox]:checked + label:before {
	content: "\2714";
	text-shadow: 1px 1px 1px rgba(0, 0, 0, .2);
	font-size: 15px;
	color: #FFCD03;
	text-align: center;
	line-height: 15px;
}

input[type=checkbox]:disabled + label {
	color: #CCCCCC;
}

input[type=checkbox]:disabled + label:before {
	background-color: #CCCCCC;
	cursor: not-allowed;
}

.btnGreen {
	background-color: #00abb7;
	border: 0;
	padding: 5px;
	padding-left: 15px;
	padding-right: 15px;
	color: #fff;
}

.btnGreen:hover {
	background-color: #028790;
	color: white;
}

.btnGreen:focus {
	background-color: #00abb7;
	border: 0;
	color: white;
}

.btnGreenLarge {
	background-color: #00abb7;
	color: #fff;
}

.btnGreenLarge:hover {
	background-color: #028790;
	color: white;
}

.btnGreenLarge:focus {
	background-color: #00abb7;
	color: white;
}

.btnLightGreen {
	background-color: #7fd5db;
	border: 0;
	padding: 5px;
	padding-left: 15px;
	padding-right: 15px;
	color: #2C4267;
}

.orderassociationmodalheader1 {
	font-size: 1.8em;
	color: #939393;
}

.orderassociationmodalheader2 {
	font-size: 1.4em;
	color: #939393;
}

.orderassociationmodalsearch {
	background-color: #ECEDEF;
}

.orderassociationmodalsearchcheckboxlabel {
	font-weight: normal !important;
	color: #A6A6A6;
}

.mainBackground {
	background-color: #fff;
	margin-top: 20px;
	border-radius: 5px;
	color: #646464;
	min-height: 700px;
	overflow: hidden;
}

.modalBackground {
	background-color: #fff;
	padding-top: 30px;
}

a.noUnderline {
	text-decoration: none;
}

.text-warning {
	color: #eac958
}

.text-success {
	color: #58bc6d
}

.text-danger {
	color: #ff6749
}

.bold {
	font-weight: bold;
}

.titleCellLeft {
	padding-right: 15px;
	text-align: right;
	font-weight: bold;
}

.formfillTitleCell {
	width: 250px;
	max-width: 250px;
	white-space: nowrap;
	padding-right: 15px;
	text-align: right;
	font-weight: bold;
	overflow: hidden;
	text-overflow: ellipsis;
}

.formfillTableCell {
	padding-left: 15px;
	padding-right: 15px;
	padding-top: 2px !important;
	padding-bottom: 2px !important;
}

.errorControl, .errorControlRed {
	border-color: red;
}

.errorControlYellow {
	border-color: #ffcd03;
}

.input-xs {
	height: 22px;
	padding: 2px 5px;
	font-size: 12px;
	line-height: 1.5;
/ / If Placeholder of the input is moved up, rem / modify this . border-radius: 3 px;
}

.formFillQuestionHeader {
	background-color: #D6DDDD;
	height: 25px;
	color: #2C4267;
	line-height: 25px;
	padding-left: 25px;
	margin-bottom: 5px;
	font-size: 1.0em;
	font-weight: bold;
}

.formFillAnswerDiv {
	padding: 5px 15px;
}

.form-control-static {
	padding-top: 0px;
	padding-bottom: 0px;
}

/*.form-control{
    height:20px;
    font-size:1.0em;
    padding:0px !important;
}*/


.smallerFontsForFormFill {
	font-size: 0.8em;
}

.popover {
	max-width: 500px; /* optional max width */
	width: intrinsic; /* Safari/WebKit uses a non-standard name */
	width: -moz-max-content; /* Firefox/Gecko */
	width: -webkit-max-content; /* Chrome */
}

.table-striped tbody tr.highlight td {
	background-color: #add8e6;
}

.table-striped tbody tr.highlightOld td {
	background-color: #7997a1;
}

/* CUSTOMDATA */
table.customdataTable > tbody > tr > td {
	padding: 2px;
}

table.customdataTable button.rowButton {
	background: none;
	padding: 0;
	margin: 1px;
	border: none;
}


/*  STICKY HEADER */

.headercell {
	background-color: #FFCD03;
	color: #2C4267;
	font-weight: bolder;
	font-size: 0.9em;
	float: left;
	border-right: 1px solid white;
	border-left: 1px solid white;
	padding: 2px 4px !important;
	overflow: hidden !important;
	white-space: nowrap;
}

.datacell {
	color: #2C4267;
	float: left;
	border-right: 1px solid white;
	border-left: 1px solid white;
	padding: 2px 4px !important;
	overflow: hidden !important;
	white-space: nowrap;
}

/* The sticky class is added to the header with JS when it reaches its scroll position */
.sticky {
	position: fixed;
	top: 60px;
	z-index: 999;
	width: 5000px;
	overflow-x: hidden;
}

.widthUtmsWorklistId {
	min-width: 70px;
	width: 70px;
}

.widthUtmsDefectId {
	min-width: 90px;
	width: 90px;
}

.widthUtmsBtr {
	min-width: 80px;
	width: 80px;
}

.widthUtmsLine {
	min-width: 105px;
	width: 105px;
}

.widthUtmsEvent {
	min-width: 105px;
	width: 105px;
}

.widthUtmsGmd {
	min-width: 120px;
	width: 120px;
}

.widthUtmsTrack {
	min-width: 70px;
	width: 70px;
}

.widthUtmsRail {
	min-width: 80px;
	width: 80px;
}

.widthUtmsFrom {
	min-width: 80px;
	width: 80px;
}

.widthUtmsTo {
	min-width: 80px;
	width: 80px;
}

.widthUtmsType {
	min-width: 130px;
	width: 130px;
}

.widthUtmsFG {
	min-width: 50px;
	width: 50px;
}

.widthUtmsAppStatus {
	min-width: 120px;
	width: 120px;
}

.widthUtmsUtmsStatus {
	min-width: 120px;
	width: 120px;
}

.widthUtmsAssigned {
	min-width: 80px;
	width: 80px;
}

.widthUtmsActions {
	min-width: 120px;
	width: 120px;
}

.widthUtmsMark {
	min-width: 40px;
	width: 40px;
}

.widthC4msErrorId {
	min-width: 120px;
	width: 120px;
}

.widthC4msCampaign {
	min-width: 80px;
	width: 80px;
}

.widthC4msEvent {
	min-width: 80px;
	width: 80px;
}

.widthC4msBtr {
	min-width: 58px;
	width: 58px;
}

.widthC4msStrk {
	min-width: 60px;
	width: 60px;
}

.widthC4msSpor {
	min-width: 90px;
	width: 90px;
}

.widthC4msFrom {
	min-width: 65px;
	width: 65px;
}

.widthC4msTo {
	min-width: 65px;
	width: 65px;
}

.widthC4msType {
	min-width: 100px;
	width: 100px;
}

.widthC4msVurd {
	min-width: 80px;
	width: 80px;
}

.widthC4msClass3 {
	min-width: 47px;
	width: 47px;
}
.datacell.widthC4msClass3 label {
	cursor: default;
}

.widthC4msVurderetGfsD {
	min-width: 85px;
	width: 85px;
}

.widthC4msVurderetJustD {
	min-width: 90px;
	width: 90px;
}

.widthC4msMethod {
	min-width: 65px;
	width: 65px;
}

.widthC4msAppStatus {
	min-width: 100px;
	width: 100px;
}

.widthC4msStatus {
	min-width: 102px;
	width: 102px;
}

.widthC4msGfs {
	min-width: 80px;
	width: 80px;
}

.widthC4msAssigned {
	min-width: 120px;
	width: 120px;
}

.widthC4msActions {
	min-width: 100px;
	width: 100px;
}

.widthC4msMark {
	min-width: 40px;
	width: 40px;
}

.stickdatadiv_container {
	overflow: auto;
}

.stickdatadiv {
	width: 2000px;
}

.stickyheaderdatarow:nth-child(2n+1) > div > div {
	background-color: #b1b6be;
	border-top: 1px solid white;
	border-bottom: 1px solid white;
}

.stickyheaderdatarow:nth-child(2n+2) > div > div {
	background-color: #d6dddd;
	border-top: 1px solid white;
	border-bottom: 1px solid white;
}

.pointycursor {
	cursor: pointer;
}

.hidden {
	display: none;
}

.stickyHighlight > div > div {
	background-color: #add8e6 !important;
}

.stickyHighlightOld > div > div {
	background-color: #7997a1 !important;
}
